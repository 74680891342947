<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-14 17:02:24
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:10:34
 * @Description: 申报词条选择
 * @FilePath: \src\components\declareItemsSelect\index.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible="show"
    width="80%"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <template>
          <!-- ------------- 检索 ------------- -->
          <div class="table-search-box">
            <!-- <div class="table-search-title">检索</div> -->
            <div class="table-search-content">
              <div class="table-search-item">
                <label>分类筛选：</label>
                <el-select size="small" v-model="commodityClassId">
                  <el-option key="All" label="全部" value="All"></el-option>
                  <el-option
                    v-for="item in itemsClassList"
                    :key="item.kcoc_id"
                    :label="item.name"
                    :value="item.kcoc_id"
                  ></el-option>
                </el-select>
              </div>

              <div class="table-search-item">
                <label>名称筛选：</label>
                <el-input
                  size="small"
                  v-model="search_key"
                  @keyup.enter.native="_LoadItems()"
                  suffix-icon="xxxx"
                ></el-input>
              </div>

              <div class="table-search-btn">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="
                    pageNo = 1;
                    _LoadItems();
                  "
                  >查询</el-button
                >
              </div>
            </div>
          </div>

          <el-table
            :data="TableData"
            border
            size="small"
            @row-dblclick="_SelectRow"
            style="width: 100%"
          >
            <!-- ID -->
            <el-table-column
              label="ID"
              prop="Aid"
              align="center"
              width="50px"
            ></el-table-column>

            <!-- 词条名称 -->
            <el-table-column
              label="词条名称"
              prop="name"
              align="center"
              width="330px"
            ></el-table-column>

            <!-- 申报名 -->
            <el-table-column
              label="词条申报名"
              prop="declare_name"
              align="center"
              width="330px"
            ></el-table-column>

            <!-- 当前状态 -->
            <el-table-column label="当前状态" align="center" width="80px">
              <template slot-scope="s">
                <el-tag
                  size="small"
                  effect="plain"
                  type="success"
                  v-if="s.row.status == 1"
                  >启用</el-tag
                >
                <el-tag size="small" effect="plain" type="danger" v-else
                  >禁用</el-tag
                >
              </template>
            </el-table-column>

            <!-- 备注 -->
            <el-table-column label="备注">
              <template slot-scope="s"
                ><span>{{ s.row.notes }}</span></template
              >
            </el-table-column>
          </el-table>

          <!-- ------------- 分页 ------------- -->
          <div class="page-box">
            <el-pagination
              background
              layout="total, prev, pager, next, sizes, jumper"
              :current-page.sync="pageNo"
              :page-size.sync="pageSize"
              :total="dataCount"
              :page-sizes="[15, 50, 100, 150, 200]"
              @current-change="_LoadItems()"
              @size-change="_LoadItems()"
            >
            </el-pagination>
          </div>
        </template>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import { getCommodityClassList, getDeclareCommodityItemsTable } from "./api"; //页面专有接口
export default {
  name: "declareItemsSelect",
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    }, //控制组件是否显示
  },
  data() {
    return {
      title: "申报词条选择器", //弹窗标题
      loading: true, //加载控制
      pageNo: 1, //当前页
      pageSize: 15, //分页行数
      dataCount: 0, //数据总行数
      search_key: "", //搜索词
      itemsClassList: [], //分类列表
      commodityClassId: "All", //词条分类ID
      TableData: [], //表格数据
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.loading = true;
        this._LoadItemsClass();
        this.$nextTick(() => {
          this._LoadItems();
          this.$nextTick(() => {
            this.loading = false;
          });
        });
      }
    },
    commodityClassId(val) {
      this.TableData = [];
      if (val !== "") {
        this._LoadItems();
      }
    },
  },
  methods: {
    //加载分类数据
    _LoadItemsClass() {
      getCommodityClassList()
        .then((res) => {
          this.itemsClassList = res.data;
        })
        .catch((err) => {
          this.itemsClassList = [];
          if (err.code === 180003) {
            this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //加载词条数据
    _LoadItems() {
      getDeclareCommodityItemsTable({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        classId: this.commodityClassId,
        searchKey: this.search_key,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 200002) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //选择词条
    _SelectRow(row) {
      this.$emit("confirm-select", {
        kdei_id: row.kdei_id,
        name: row.name,
        declare_name: row.declare_name,
      });
      this.$nextTick(() => {
        this._HandleClose();
      });
    },

    //关闭
    _HandleClose() {
      this.$emit("update:show", false); //触发input事件，并传入新值
      Object.assign(this.$data, this.$options.data());
    },
  },
  mounted() {
    this._LoadItemsClass();
  },
};
</script>
