<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-09 16:50:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:07
 * @Description: 创建 修改 货品词条
 * @FilePath: \src\views\App\Iemma\NUCommodityItem.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="110px">
          <el-form-item label="货品名称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="货品英文名：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.en_name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="附加检索词："
            style="width: 85%"
            class="search-box"
          >
            <el-input
              size="small"
              v-model="m.search_key"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="货品分类：" style="width: 45%">
            <el-select size="small" v-model="m.kcoc_id">
              <el-option
                v-for="item in CommodityClassList"
                :key="item.kcoc_id"
                :label="item.name"
                :value="item.kcoc_id"
              >
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新货品分类列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetCommodityClassList()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="申报名称：" style="width: 45%">
            <el-input
              size="small"
              :readonly="kdei_readonly"
              v-model="m.kdei_name"
              @click.native="_OpenDeclareSelectModel"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="货品敏感等级：" style="width: 90%">
            <el-radio v-model="m.sensitive_level" :label="190010"
              >Ⅰ级【普货】</el-radio
            >
            <el-radio v-model="m.sensitive_level" :label="190011"
              >Ⅱ级【内衣裤类】</el-radio
            >
            <el-radio v-model="m.sensitive_level" :label="190012"
              >Ⅲ级【成人用品类】</el-radio
            >
            <el-radio v-model="m.sensitive_level" :label="190013"
              >Ⅳ级【证件/文件类】</el-radio
            >
          </el-form-item>

          <el-form-item label="货品状态：" v-if="id !== 3" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateCommodityItems()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateCommodityItems()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>

    <!-- 申报词条选择器 -->
    <declare-items-select
      @confirm-select="_SubmitDeclareItem"
      :show.sync="show_declare_select"
    ></declare-items-select>
  </el-dialog>
</template>

<script>
import {
  createCommodityItem,
  updateCommodityItem,
  getCommodityItemInfoById,
  getCommodityClassList,
} from "./api"; //页面专有接口
import DeclareItemsSelect from "@/components/declareItemsSelect";
export default {
  components: {
    DeclareItemsSelect,
  },
  data() {
    return {
      m: {
        kcoc_id: "", //货品分类ID
        kdei_id: "", //申报ID
        name: "", //货品名称
        kdei_name: "", //申报名称
        en_name: "", //货品英文名
        search_key: "", //检索词
        sensitive_level: 190010, //敏感等级
        status: 1, //状态
        notes: "", //词条描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      CommodityClassList: [], //货品分类列表
      show_declare_select: false, //显示申报词条选择器
      kdei_readonly: true,
    };
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetCommodityClassList(); //获取货品分类列表
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加货品词条";
          this.loading = false;
        } else {
          //修改
          this._GetWarehouseInfo(id);
          this.title = "修改货品词条";
        }
      });
    },

    //添加货品分类
    _CreateCommodityItems() {
      var m = this.m;
      createCommodityItem({
        kcoc_id: m.kcoc_id,
        kdei_id: m.kdei_id,
        name: m.name,
        en_name: m.en_name,
        search_key: m.search_key,
        sensitive_level: m.sensitive_level,
        status: m.status,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改货品分类
    _UpdateCommodityItems() {
      var m = this.m;
      updateCommodityItem({
        kcoi_id: this.id,
        kcoc_id: m.kcoc_id,
        kdei_id: m.kdei_id,
        name: m.name,
        en_name: m.en_name,
        search_key: m.search_key,
        sensitive_level: m.sensitive_level,
        status: m.status,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取货品分类信息
    _GetWarehouseInfo(kcoi_id) {
      getCommodityItemInfoById({ kcoi_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.kcoc_id = d.kcoc_id;
          m.kdei_id = d.kdei_id;
          m.name = d.name;
          m.en_name = d.en_name;
          m.kdei_name = d.kdei_name;
          m.search_key = d.search_key;
          m.sensitive_level = d.sensitive_level;
          m.status = d.status;
          m.notes = d.notes;
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取货品分类列表
    _GetCommodityClassList() {
      getCommodityClassList()
        .then((res) => {
          this.CommodityClassList = res.data;
        })
        .catch((err) => {
          this.CommodityClassList = [];
          if (err.code === 180003) {
            this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开申报词条选择器
    _OpenDeclareSelectModel() {
      this.show_declare_select = true;
    },

    //确认申报词条
    _SubmitDeclareItem(item) {
      this.m.kdei_id = item.kdei_id;
      this.m.kdei_name = item.name;
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>
<style>
.search-box .el-form-item__content {
  width: calc(100% - 110px);
}
</style>
