/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:02:47
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\Iemma\api.js
 */

import http from "@/api";

/**************************** 词条分类操作 ****************************/

// 添加词条分类
export const createCommodityClass = (params) =>
  http.post("/v1/CommodityClass/CreateCommodityClass", params);

//修改词条分类
export const updateCommodityClass = (params) =>
  http.put("/v1/CommodityClass/UpdateCommodityClass", params);

//删除词条分类
export const delCommodityClass = (params) =>
  http.delete("/v1/CommodityClass/DelCommodityClass", params);

//获取分类词条信息
export const getCommodityClassInfoById = (params) =>
  http.get("/v1/CommodityClass/GetCommodityClassInfoById", params);

//获取分类词条表格
export const getCommodityClassTable = (params) =>
  http.get("/v1/CommodityClass/GetCommodityClassTable", params);

//获取分类词条列表
export const getCommodityClassList = (params) =>
  http.get("/v1/CommodityClass/GetCommodityClassList", params);

/**************************** 申报词条操作 ****************************/

// 添加申报词条词条
export const createDeclareCommodityItem = (params) =>
  http.post("/v1/DeclareCommodity/CreateDeclareCommodityItem", params);

//修改申报词条词条
export const updateDeclareCommodityItem = (params) =>
  http.put("/v1/DeclareCommodity/UpdateDeclareCommodityItem", params);

//删除申报词条词条
export const delDeclareCommodityItem = (params) =>
  http.delete("/v1/DeclareCommodity/DelDeclareCommodityItem", params);

//获取申报词条词条信息
export const getDeclareCommodityItemInfoById = (params) =>
  http.get("/v1/DeclareCommodity/GetDeclareCommodityItemInfoById", params);

//获取申报词条词条表格
export const getDeclareCommodityItemsTable = (params) =>
  http.get("/v1/DeclareCommodity/GetDeclareCommodityItemsTable", params);

/**************************** 货品词条操作 ****************************/

// 添加货品词条
export const createCommodityItem = (params) =>
  http.post("/v1/Commodity/CreateCommodityItem", params);

//修改货品词条
export const updateCommodityItem = (params) =>
  http.put("/v1/Commodity/UpdateCommodityItem", params);

//删除货品词条
export const delCommodityItem = (params) =>
  http.delete("/v1/Commodity/DelCommodityItem", params);

//获取货品词条信息
export const getCommodityItemInfoById = (params) =>
  http.get("/v1/Commodity/GetCommodityItemInfoById", params);

//获取货品词条表格
export const getCommodityItemsTable = (params) =>
  http.get("/v1/Commodity/GetCommodityItemsTable", params);
